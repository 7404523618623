<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col cols="6">
                <b-form-group :label="$t(labelPrefix+'storage_order_statuses')">
                    <multiselect v-model="form.storage_order_statuses"
                                 name="storage_order_statuses"
                                 :searchable="true"
                                 :options="Object.keys(getStatuses())"
                                 :multiple="true"
                                 select-label=""
                    ></multiselect>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group :label="$t(labelPrefix+'position_added_status')">
                    <multiselect v-model="form.position_added_status"
                                 name="position_added_status"
                                 :searchable="true"
                                 :options="Object.keys(getStatuses())"
                                 select-label=""
                    ></multiselect>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group :label="$t(labelPrefix+'requested_cancellation_status')">
                    <multiselect v-model="form.requested_cancellation_status"
                                 name="requested_cancellation_status"
                                 :searchable="true"
                                 :options="Object.keys(getStatuses())"
                                 select-label=""
                    ></multiselect>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group :label="$t(labelPrefix+'erika_default_status')">
                    <multiselect v-model="form.erika_default_status"
                                 name="erika_default_status"
                                 :searchable="true"
                                 :options="Object.keys(getStatuses())"
                                 select-label=""
                    ></multiselect>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group>
                    <template v-slot:label>
                        {{ $t(labelPrefix + 'amazon_imported_status') }}
                        <b-button variant="info"
                                  v-b-popover.hover.top="$t('settings.status_map.help.amazon_imported_status')"
                        >
                            <font-awesome-icon icon="info"/>
                        </b-button>
                    </template>
                    <multiselect v-model="form.amazon_imported_status"
                                 name="amazon_imported_status"
                                 :searchable="true"
                                 :options="Object.keys(getStatuses())"
                                 select-label=""
                    ></multiselect>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group>
                    <template v-slot:label>
                        {{ $t(labelPrefix + 'kva_readonly_status') }}
                        <b-button variant="info"
                                  v-b-popover.hover.top="$t('settings.status_map.help.kva_readonly_status')"
                        >
                            <font-awesome-icon icon="info"/>
                        </b-button>
                    </template>
                    <multiselect v-model="form.kva_readonly_status"
                                 name="kva_readonly_status"
                                 :searchable="true"
                                 :options="Object.keys(getStatuses())"
                                 select-label=""
                                 :multiple="true"
                    ></multiselect>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group>
                    <template v-slot:label>
                        {{ $t(labelPrefix + 'transactions_ignore_statuses') }}
                        <b-button variant="info"
                                  v-b-popover.hover.top="$t('settings.status_map.help.transactions_ignore_statuses')"
                        >
                            <font-awesome-icon icon="info"/>
                        </b-button>
                    </template>
                    <multiselect v-model="form.transactions_ignore_statuses"
                                 name="transactions_ignore_statuses"
                                 :searchable="true"
                                 :multiple="true"
                                 :options="Object.keys(getStatuses())"
                                 select-label=""
                    ></multiselect>
                </b-form-group>
            </b-col>
        </b-row>

        <b-card-group deck>
            <b-card
                header-tag="header"
            >
                <b-card-header>
                    {{ $t('settings.status_map.label.created_entity_status') }}
                    <b-button variant="info" class="ml-1"
                              v-b-popover.hover.top="$t('settings.status_map.help.created_entity_status')"
                    >
                        <font-awesome-icon icon="info"/>
                    </b-button>
                </b-card-header>
                <b-card-text>
                    <b-row>
                        <b-col cols="6" v-for="(entity_type, index) in getEntityTypes()"
                               :key="index">
                            <b-form-group>
                                <template v-slot:label>
                                    {{ $t(labelPrefix + 'created_entity_status_' + entity_type) }}
                                </template>
                                <zw-select-group v-model="form['created_entity_status_'+entity_type]"
                                                 :options="getTypeStatuses(entity_type)"
                                                 :name="'created_entity_status_'+entity_type"
                                                 :label-prefix="labelPrefix"
                                                 :disable-label="true"
                                ></zw-select-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-card-group>

        <b-row>
            <b-col sm="12" class="text-xs-center mt-2">
                <b-row align-h="end">
                    <b-col sm="6" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>

        </b-row>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsStatusMap',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            defaultForm: {
                position_added_status: '',
                storage_order_statuses: null,
            },
            form: {},
            labelPrefix: 'settings.status_map.label.',
        }
    },
    methods: {
        ...mapGetters('Settings', ['getStatusMap']),
        ...mapGetters('CommonData', ['getStatuses', 'getEntityTypes']),
        ...mapGetters('EntityStatus', ['getEntityStatusesList']),
        shown() {
            this.loading = true
            const settingsStatusMap = this.$store.dispatch('Settings/fetchStatusMap');
            const entityStatusesList = this.$store.dispatch('EntityStatus/fetchUsersList');

            Promise.all([settingsStatusMap, entityStatusesList]).then(() => {
                this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getStatusMap()}))
                this.loading = false
            });
        },
        getTypeStatuses(type) {
            let options = {}
            this.getEntityStatusesList().forEach((val) => {
                if (val.entity_type == type) {
                    options[val.id] = val.name
                }
            })
            return options
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$store.dispatch('Settings/save', this.form).then((response) => {
                        this.afterSave(response)
                    }).catch(errors => {
                        this.$refs['observer'].setErrors(errors)
                        this.showValidationError()
                    })
                } else {
                    this.showValidationError()
                }
            })
        },
        afterSave(response) {
            if (response.status == 200 || response.status == 201) {
                this.$root.showMessage(
                    this.$t('common.form.title.success'),
                    this.$t('common.toasts.success_updated'),
                    'success'
                )
                this.shown()
            }
            this.$root.afterSettingsUpdate()
        },
    },
    mounted() {
        this.shown()
    }
}
</script>